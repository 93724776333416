import { ref, onMounted, onUnmounted } from 'vue'
import type { Breakpoints } from '@vueuse/core'

const breakpoints: Breakpoints = {
  xs: 320,
  md: 768,
  lg: 1024,
  xl: 1240,
}

export function viewportIsGreaterThan(breakpoint: 'xs' | 'md' | 'lg' | 'xl') {
  const isGreaterThan = ref(false)
  const $device = useDevice()

  const updateValue = () => {
    if (typeof window !== 'undefined') {
      isGreaterThan.value = window.innerWidth > breakpoints[breakpoint]
    } else {
      if (breakpoint === 'xs') {
        isGreaterThan.value = $device.isDesktopOrTablet
      } else {
        isGreaterThan.value = $device.isDesktop
      }
    }
  }

  onMounted(() => {
    updateValue()
    window.addEventListener('resize', updateValue)
  })

  onUnmounted(() => {
    window.removeEventListener('resize', updateValue)
  })

  return isGreaterThan
}

export function viewportIsGreaterOrEquals(
  breakpoint: 'xs' | 'md' | 'lg' | 'xl',
) {
  const isGreaterOrEquals = ref(false)
  const $device = useDevice()

  const updateValue = () => {
    if (typeof window !== 'undefined') {
      isGreaterOrEquals.value = window.innerWidth >= breakpoints[breakpoint]
    } else {
      if (breakpoint === 'xs') {
        isGreaterOrEquals.value = $device.isDesktopOrTablet
      } else {
        isGreaterOrEquals.value = $device.isDesktop
      }
    }
  }

  onMounted(() => {
    updateValue()
    window.addEventListener('resize', updateValue)
  })

  onUnmounted(() => {
    window.removeEventListener('resize', updateValue)
  })

  return isGreaterOrEquals
}

export function viewportIsLessThan(breakpoint: 'xs' | 'md' | 'lg' | 'xl') {
  const isLessThan = ref(false)
  const $device = useDevice()

  const updateValue = () => {
    if (typeof window !== 'undefined') {
      isLessThan.value = window.innerWidth < breakpoints[breakpoint]
    } else {
      if (breakpoint === 'xs') {
        isLessThan.value = $device.isMobile
      } else {
        isLessThan.value = $device.isMobileOrTablet
      }
    }
  }

  onMounted(() => {
    updateValue()
    window.addEventListener('resize', updateValue)
  })

  onUnmounted(() => {
    window.removeEventListener('resize', updateValue)
  })

  return isLessThan
}
